$(function () {
    initProductOverview();
    initDetail();
    initSizeConfigurator();
    initHideColorOptions();

    $('[data-toggle=tooltip]').tooltip({
        content: function () {
            return $(this).prop('title');
        }
    });
});

if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (fn, scope) {
        for (let i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
        for (let i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            let o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            let len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            let n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

let globalVariants = [];
let globalSelected = [];

function containsAll(needles, haystack) {
    for (let i = 0, len = needles.length; i < len; i++) {
        if ($.inArray(needles[i], haystack) === -1) return false;
    }
    return true;
}

function initHideColorOptions() {
    const kleurElement = document.getElementById('kleur');

    if (kleurElement) {
        const optionElement = kleurElement.closest('.option');

        if (optionElement) {
            const formGroup = optionElement.querySelector('.form-group');
            if (formGroup) {
                formGroup.style.display = 'none';
            }
        }
    }
}

function initProductOverview() {
    if ($('#products').length > 0) {
        initShoppingCart();

        let $button = $('#products .page-content .read-more');
        if ($('#products .page-content .text p').length > 1) {
            let height = $('#products .page-content .text').outerHeight();

            $('#products .page-content .text').css({
                height: $('#products .page-content .text').children('p').first().outerHeight()
            });

            $button.on('click', function () {
                $('#products .page-content .text').toggleClass('active');

                if ($('#products .page-content .text').hasClass('active')) {
                    $button.find('i').removeClass('icon-plus').addClass('icon-minus');
                    $button.find('span').text('Lees minder');

                    $('#products .page-content .text').css({
                        height: height
                    });
                } else {
                    $button.find('i').removeClass('icon-minus').addClass('icon-plus');
                    $button.find('span').text('Lees meer');

                    $('#products .page-content .text').css({
                        height: $('#products .page-content .text').children('p').first().outerHeight()
                    });
                }
            });
        } else {
            $button.hide();
        }

        $('.product .inner').matchHeight();

        $('.product').each(function () {
            const $product = $(this);

            const $image = $product.find('.image');

            $(this).find('.variants .slider .variant').click(function (e) {
                const $variant = $(this);
                e.preventDefault();

                $product.find('.variants .slider .variant').removeClass('slick-current');
                $variant.addClass('slick-current');

                const src = $variant.find('img').attr('src');

                $image.attr('src', src);
            });
            $(this).find('.variants .slider').slick({
                mobileFirst: true,
                vertical: true,
                verticalSwiping: true,
                focusOnSelect: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '<i class="icon icon-chevron-up prev"></i>',
                nextArrow: '<i class="icon icon-chevron-down next"></i>',
                dots: false,
                infinite: false
            });

            $product.find('.variants .slider .variant [data-media-id=' + $image.data('media-id') + ']').parent().click();

            $(this).find('.variants .slider').on('click', function (event) {
                event.preventDefault();
            });
        });
    }
}


function initDetail() {
    if ($('#product:not(.combination)').length > 0) {
        initDetailGallery();
        initShoppingCart();
        initImageLightbox();
        initVariants();
        initProductTabs();
        initProductTip();
    }
}

function initDetailGallery() {
    $('#product .gallery .nav').show();
    if ($('#product .gallery').length > 0) {
        $('#product .gallery .images').slick({
            mobileFirst: true,
            slidesToShow: 1,
            fade: true,
            arrows: false,
            dots: true,
            slide: '.slide',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        dots: false
                    }
                }
            ]
        });

        if ($('#product .gallery .nav .slide').length < 2) {
            $('#product .gallery .nav').hide();
        } else {
            $('#product .gallery .nav').slick({
                mobileFirst: true,
                slidesToShow: 2,
                infinite: false,
                focusOnSelect: true,
                asNavFor: '#product .gallery .images',
                slide: '.slide',
                arrows: false,
                vertical: false,
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 6
                        }
                    }
                ]
            });

            $('#product .gallery .nav .prev-arrow').on('click', function () {
                $('#product .gallery .nav').slick('slickPrev');
            });
            $('#product .gallery .nav .next-arrow').on('click', function () {
                $('#product .gallery .nav').slick('slickNext');
            });
        }
    }

    $('[data-action="toggle-video-modal"]').on('click', function () {
        const $modal = $('#videoModal');
        const $video = $(this);

        const id = $video.data('id');

        $modal.find('iframe').attr('src', 'https://www.youtube.com/embed/' + id);
        $modal.modal('show');
    });
    hideArrow();
    $(window).resize(function () {
        hideArrow();
    });

}

function hideArrow() {

    var amountOfSlides = $('.slick-slide', '#special-gallery').length;
    var amountOfActiveSlides = $('.slick-active', '#special-gallery').length;

    if (amountOfSlides === amountOfActiveSlides) {
        $('.next-arrow', '#special-gallery').hide();
        $('.prev-arrow', '#special-gallery').hide();
    } else {
        $('.next-arrow', '#special-gallery').show();
        $('.prev-arrow', '#special-gallery').show();
    }
}

function getColorImage(variantId) {
    let selectedVariant = null;
    globalVariants.forEach(function (variant) {
        if (variant.id === variantId) {
            selectedVariant = variant;
        }
    });

    if (null === selectedVariant) {
        return null;
    }

    let colorOption = null;
    selectedVariant.options.forEach(function (option) {
        if (option.name === 'Kleur') {
            colorOption = option;
        }
    });

    if (null === colorOption || !colorOption.value.images[0]) {
        return null;
    }

    return colorOption.value.images[0].large;
}

function initShoppingCart() {
    $('.btn_buy').on('click', function (e) {
        e.preventDefault();
        const $button = $(this);

        if ($button.hasClass('disabled')) {
            return;
        }

        const $variant = $('#variant');

        if ($variant.val() !== 'null') {
            const $form = $('#addToCartForm');
            const data = $form.serialize();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                    dataLayer.push({
                        event: "add_to_cart",
                        ecommerce: {
                            currency: "EUR",
                            value: $('#productCartModal').data('product-price'),
                            items: [
                                {
                                    item_id: $('#productCartModal').data('product-id'),
                                    item_name: $('#productCartModal').data('product-name'),
                                    affiliation: "Olfit.nl",
                                    // coupon: "SUMMER_FUN",
                                    // discount: 2.22,
                                    index: 0,
                                    item_brand: $('#productCartModal').data('product-brand'),
                                    item_category: $('#productCartModal').data('product-category'),
                                    // item_list_id: "related_products",
                                    // item_list_name: "Related Products",
                                    // item_variant: "green",
                                    // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                                    price: $('#productCartModal').data('product-price'),
                                    quantity: 1
                                }
                            ]
                        }
                    });

                    updateCart(cart);

                    // dataLayer.push({
                    //     'event': 'addToCart',
                    //     'ecomm_prodid': $('#productCartModal').data('product-id'),
                    //     'ecomm_totalvalue': cart.total_plus_shipping
                    // });
                }
            });

            function formatEUPrice(number) {
                return number.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }

            const variantId = parseInt($variant.val());
            const src = getColorImage(variantId);

            const urlParams = new URLSearchParams(data);
            const quantity = parseInt(urlParams.get('quantity'), 10);

            const priceElement = document.querySelector('.cart-popup .price');
            let priceText = priceElement.textContent.trim();

            priceText = priceText.replace('€', '').trim();
            const price = parseFloat(priceText.replace('.', '').replace(',', '.'));
            const total = price * quantity;

            const $productCartModal = $('#productCartModal');
            const $cartPopup = $('.cart-popup');

            const subtotal = document.getElementById('cartPopupSubTotal');
            if (subtotal) {
                subtotal.textContent = `€${formatEUPrice(total)}`;
            }

            if (null !== src) {
                const $image = $cartPopup.find('img');
                $image.attr('src', src);
            }

            if ($(window).width() > 992) {
                if ($(window).scrollTop() > 40) {
                    $('header nav').addClass('overlay-effect');
                } else {
                    $('header nav').removeClass('overlay-effect');
                }
            }

            $('.cart-popup').addClass('active');

            $('.continue_btn').on('click', function (e) {
                e.preventDefault();
                $('.cart-popup').removeClass('active');
                $('.cart-popup').hide();
                $('.overlay').removeClass('active');
                $('header nav').removeClass('overlay-effect');
            });

            $('.overlay').toggleClass('active');

            $('header .cart-popup').fadeToggle(100);

            // $productCartModal.modal('show');
        } else {
            $('#options .option').each(function (index) {
                const $option = $(this);
                const $select = $option.find('select');

                if ($select.val() === '') {
                    const $label = $option.find('label');
                    $label.addClass('danger');
                }
            });

            $('#options').append($('<i>', {class: 'danger'}).text('Maak uw keuze uit de aangegeven opties'));
        }
    });

    $('#quantity').on('input', function () {
        updateOptions(globalVariants, globalSelected);
    });
}

function initImageLightbox() {
    $('.images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}


function initVariants() {
    const $variants = $(document).find('[data-variants]');
    const variants = JSON.parse($variants.text());

    let selected = [];
    let selectedColorValue = null;
    variants.forEach(function (variant) {
        if (variant.selected) {
            selected = getValueIdsForVariant(variant);
            selectedColorValue = getColorValueForVariant(variant);
        }
    })

    updateOptions(variants, selected);

    if (null !== selectedColorValue) {
        updateImages(selectedColorValue);
    }
}

function parseOptions(variants) {
    let options = [];

    variants.forEach(function (variant) {
        variant.options.forEach(function (vo) {
            let co;
            options.forEach(function (option) {
                if (option.name === vo.name) {
                    co = option;
                }
            });
            if (typeof co === 'undefined') {
                co = {
                    id: vo.id,
                    name: vo.name,
                    order: vo.order,
                    values: []
                };
                options.push(co);
            }
            let cov;
            co.values.forEach(function (value) {
                if (value.name === vo.value.name) {
                    cov = value;
                }
            });
            if (typeof cov === 'undefined') {
                cov = {
                    id: vo.value.id,
                    name: vo.value.name,
                    images: vo.value.images,
                    order: vo.value.order,
                };
                co.values.push(cov);
            }
            co.values.sort((cov1, cov2) => cov1.order - cov2.order);
        });
    });

    options.sort((co1, co2) => co1.order - co2.order);

    return options;
}

function findCombinations(variants, selected) {
    const ids = [];

    selected.forEach(function (id) {
        variants.forEach(function (variant) {
            const vids = [];
            variant.options.forEach(function (vo) {
                vids.push(vo.value.id);
            });

            if (containsAll(selected, vids)) {
                vids.forEach(function (id) {
                    if (ids.indexOf(id) === -1) {
                        ids.push(id);
                    }
                });
            }
        });
    });
    return ids;
}

function getVariant(variants, selected) {
    let variant = null;
    variants.forEach(function (v) {
        const vids = [];
        v.options.forEach(function (vo) {
            vids.push(vo.value.id);
        });
        if (vids.every((val) => selected.includes(val))) {
            variant = v;
        }
    });
    return variant;
}

function getSelected() {
    const selected = [];
    $('#options').find('input:checked').each(function () {

        let id = parseInt($(this).data('id'));
        selected.push(isNaN(id) ? null : id)
    });
    return selected;
}

function getValueIdsForVariant(variant) {
    const values = [];
    variant.options.forEach(function (option) {
        values.push(option.value.id);
    });
    return values;
}

function getColorValueForVariant(variant) {
    let value = null
    variant.options.forEach(function (option) {
        console.log(option);
        if (option.name.toLowerCase() === 'kleur') {
            value = option.value
        }
    });
    return value;
}

function findMatchingVariants(variants, selected) {
    const matching = [];
    variants.forEach(function (variant) {
        const vids = getValueIdsForVariant(variant);

        if (containsAll(selected, vids)) {
            matching.push(variant);
        }
    });
    return matching;
}

function getVariantBySize(variants, s) {
    let variant = null;
    variants.forEach(function (v) {
        v.options.forEach(function (vo) {
            if (vo.value.name == s) {
                variant = v;
            }
        });
    });
    return variant;
}

function getAvailableValues(option, variants, selected, currentOption) {
    const previousSelected = [];
    selected.forEach(function (value, i) {
        if (i < currentOption) {
            previousSelected.push(value);
        }
    });

    option.values.forEach(function (value) {
        value.disabled = !isActiveValue(value, variants, previousSelected);
    });

    const combinations = findCombinations(variants, previousSelected);
    if (combinations.length > 0) {
        const values = [];
        option.values.forEach(function (value) {
            if (combinations.indexOf(value.id) !== -1) {
                values.push(value);
            }
        });

        return values;
    }

    return option.values;
}

function getEnabledValues(option, variants, selected, currentOption) {
    const values = getAvailableValues(option, variants, selected, currentOption);

    let enabled = [];
    values.forEach(function (value) {
        if (value.disabled === false) {
            enabled.push(value);
        }
    });

    return enabled;
}

function getValueById(option, id) {
    for (let i = 0; i < option.values.length; i++) {
        const value = option.values[i];
        if (value.id === id) {
            return value;
        }
    }

    return null;
}

function isActiveValue(value, variants, previousSelected) {
    const matchingVariants = findMatchingVariants(variants, previousSelected);

    let active = false;
    matchingVariants.forEach(function (variant) {
        if (variant.active) {
            variant.options.forEach(function (option) {
                if (option.value.id === value.id) {
                    active = true;
                }
            });
        }
    });
    return active;
}

function selectVariant(variants, selected) {
    const variant = getVariant(variants, selected);

    // if (null !== variant && (variant.stock > 0)) {
    if (null !== variant) {
        $('#variant').val(variant.id);
        $('#variant-stock').val(variant.stock);
        $('#variant-delivery-time').val(variant.delivery_time);

        const $price = $('<div/>', {class: 'price', id: 'selectedVariantPrice'});
        if (null !== variant.discount_price) {
            const $sale = $('<div/>', {class: 'sale'})
                .text('Aanbieding')
                .append($('<span/>', {class: 'old'}).text(variant.price));
            $price.append($sale).append(variant.discount_price);
        } else {
            $price.text(variant.price);
        }
        $price.append($('<small/>').text('(incl. btw)'));

        $('#selectedVariantPrice').replaceWith($price);

        const quantity = parseInt($('#quantity').val());

        const $form = $('#addToCartForm');
        const $button = $form.find('.btn_buy');
        $button.removeClass('disabled');

        if (variant.stock >= quantity) {
            $button.removeClass('disabled-buy-btn');

            if (null !== variant.delivery_time) {
                console.log(variant.delivery_time);
                $('#selectedVariantDeliveryTime').html(variant.delivery_time);
                $('.delivery_time_visible').fadeIn(500);
            }
        } else {
            $('#selectedVariantDeliveryTime').html('');

            if (null !== variant.out_of_stock_delivery_time) {

                if (!variant.out_of_stock_can_add_to_cart) {
                    $button.addClass('disabled');
                }

                var h = variant.out_of_stock_delivery_time.replace("...", parseInt(variant.stock));

                $('#selectedVariantDeliveryTime').html('<span>' + h + '</span>');
                $('.delivery_time_visible').fadeIn(500);
            }
        }

        // Modal
        let options = '';
        options += '<div class="options">';
        variant.options.forEach(function (option) {
            options += '<span class="option"><strong>' + option.name + '</strong>: ' + option.value.name + '</span>';
        });
        options += '</div>';

        $('#modalOptions').html(options);
        $('#modalPrice').replaceWith($price.clone());
    } else {
        $('#variant').val('null');
        $('.delivery_time_visible').hide();
    }
}

function updateOptions(variants, selected = []) {
    globalVariants = variants;
    globalSelected = selected;

    const options = parseOptions(variants);

    console.log(variants);

    selectVariant(variants, selected);

    $('#options').empty();

    let currentOption = 0;
    let $placeholder = $('<div/>', {class: 'placeholder'}).text('Maak je keuze om door te gaan');

    options.forEach(function (option, index) {
        const $option = $('<div/>', {class: 'option'});
        const $wrapper = $('<div/>', {class: 'form-group'});

        const $label = $('<label/>').text('Kies je ' + option.name.toLowerCase());

        $wrapper.append($label);

        const $values = $('<div/>').appendTo($wrapper);
        const $select = $('<div/>', {id: option.name.toLowerCase(), class: 'options-wrap'}).appendTo($values);

        const enabled = getEnabledValues(option, variants, selected, currentOption);
        if (enabled.length == 1) {
            selected[currentOption] = enabled[0].id;
            selectVariant(variants, selected);
        }

        const values = getAvailableValues(option, variants, selected, currentOption);

        if (currentOption === 0 || null != selected[currentOption - 1]) {
            $option.show();

            values.forEach(function (value) {
                if ((selected.indexOf(value.id) !== -1 || enabled.length === 1) && !value.disabled) {
                    value.selected = true;
                }

                let text = value.name;
                if (value.disabled) {
                    switch (option.name) {
                        case 'Kleur':
                            return;
                        case 'Maat':
                            text = value.name + (value.disabled ? ' (Niet meer leverbaar)' : '');
                            break;
                    }
                }

                let $wrapper = $('<div/>', {
                    class: 'radio-wrap'
                });

                let $value = $('<input/>', {
                    value: value.id,
                    type: 'radio',
                    id: option.name.toLowerCase() + '-' + value.id,
                    name: option.name.toLowerCase()
                })
                    .attr('data-type', option.name)
                    .attr('data-id', value.id)
                    .attr('data-order', value.order)
                    .attr('disabled', value.disabled);

                if (value.selected) {
                    $value.attr('checked', true);
                }

                let $label = $('<label/>', {
                    for: option.name.toLowerCase() + '-' + value.id,
                    text: text
                });

                $wrapper.append($value).append($label);
                $select.append($wrapper);
            });

            $select.on('change', function () {
                const currentOption = $select.closest('.option').index();
                const selected = getSelected();

                $('#options .option').each(function (index) {
                    const $opt = $(this);
                    if (index > currentOption) {
                        selected[index] = null;
                        $opt.hide();
                    }
                });

                const $nextOption = $select.closest('.option').next('.option');
                if ($nextOption.length) {
                    $nextOption.show();
                    $placeholder.insertAfter($nextOption).show();
                } else {
                    $placeholder.hide();
                }

                updateOptions(variants, selected);
            });
        } else {
            $option.hide();

            selected.forEach(function (value, i) {
                if (i > currentOption - 1) {
                    selected[i] = null;
                }
            });
        }

        if (option.name.toLowerCase() === 'kleur') {
            const $images = $('<div/>', {class: 'images'});
            const $imagesLabel = $('<div/>', {class: 'images-label'}).text('Kies je kleur');
            $images.prepend($imagesLabel);

            values.forEach(function (value, i) {
                if (selected.indexOf(value.id) !== -1) {
                    value.selected = true;
                }

                if (value.disabled) {
                    return;
                }



                if (value.images.length > 0) {
                    const image = value.images[0];

                    const $img = $('<img/>', {src: image.small, alt: value.name, style: 'width: 50px;'});
                    const $image = $('<div/>', {class: 'image'}).append($img)
                        .attr('data-id', value.id);

                    if (value.selected) {
                        $image.addClass('selected');
                    }

                    $image.on('click', function () {
                        const $image = $(this);
                        const id = $image.data('id');

                        const value = getValueById(option, id);
                        if (null !== value) {
                            updateImages(value);
                        }

                        $select.find('input[value='+ id +']').trigger('click');

                        $image.parent().find('.image').removeClass('selected');
                        $image.addClass('selected');
                    });

                    $images.append($image);
                }
            });

            $select.on('change', function () {
                const id = parseInt($(this).find('input[type=radio]:checked').val());
                const $image = $('.image[data-id="' + id + '"]');

                const $tip = $('[data-toggle="productTip"]').parent();
                $tip.addClass('visible');

                const value = getValueById(option, id);

                if (null !== value) {
                    updateImages(value);
                }

                $select.val(id).trigger('change');

                $image.parent().find('.image').removeClass('selected');
                $image.addClass('selected');
            });

            $option.append($images);
        } else if (option.name.toLowerCase() === 'maat') {
            const $productTip = $('[data-toggle="productTip"]').parent();
            const $clone = $productTip.clone();
            $productTip.hide();

            if ($productTip.length > 0) {
                $clone.show();
                $wrapper.find('.options-wrap').append($clone);

                $clone.find('[data-toggle=productTip]').off().on('click', function (e) {
                    e.preventDefault();
                    $(this).parent().find('.tip').toggleClass('show');
                });
            }

            if ($('#sizeConfiguratorModal').length > 0) {
                $wrapper.append($('<a href="#" class="sizeconfig" data-toggle="modal" data-target="#sizeConfiguratorModal"><i class="icon icon-information-logotype-in-a-circle"></i> Bepaal je maat</a>'));
            }
        }

        $option.append($wrapper);

        $('#options').append($option);

        // Insert placeholder after each option
        // $placeholder.clone().insertAfter($option);

        currentOption++;

        const checkedItems = document.querySelectorAll('#options input[type=radio]:checked');

        checkedItems.forEach(function (item) {
            item.closest('.radio-wrap').classList.add('selected');
        });
    });

    // Show placeholder after the last visible option
    $('.option:visible').last().next('.placeholder').show().siblings('.placeholder').remove();

    // If no options are visible, show placeholder at the top
    if ($('.option:visible').length === 0) {
        $('#options').prepend($placeholder.show());
    }

    initHideColorOptions();
}

function updateImages(value) {
    const $imageContainer = $('#product .gallery .images');
    const $thumbContainer = $('#product .gallery .nav');

    try {
        $imageContainer.slick('unslick');
        $thumbContainer.slick('unslick');
    } catch (e) {
        //
    }

    $imageContainer.find('.slide').remove();

    const videos = [];
    $thumbContainer.find('.slide.video').each(function () {
        videos.push($(this).clone());
    });

    $thumbContainer.find('.slide').remove();

    value.images.forEach(function (image) {
        const $image = $('<img/>', {src: image.large, alt: value.name});
        const $imageLink = $('<a>', {href: image.large}).append($image).append($('<i/>', {class: 'icon icon-search-plus'}));
        const $imageSlide = $('<div>', {class: 'slide'}).append($imageLink);
        $imageContainer.append($imageSlide);

        const $thumb = $('<img/>', {src: image.small, alt: value.name});
        const $thumbSlide = $('<div>', {class: 'slide'}).append($thumb);
        $thumbContainer.append($thumbSlide);
    });

    videos.forEach(function (video) {
        $thumbContainer.append(video);
    });

    initDetailGallery();
}

function initSizeConfigurator() {
    if ($('#sizeConfiguratorModal').length > 0) {

        $('#sizeConfiguratorModal .modal-footer button').on('click', function (e) {
            if ($('#sizeConfiguratorModal #overview').is(':visible')) {
                e.preventDefault();
                $('#sizeConfiguratorModal #overview').fadeOut(function (i, el) {
                    $('#sizeConfiguratorModal #selection').fadeIn();
                });
                $('#sizeConfiguratorModal .modal-footer button').text('Bereken');
            }
        });

        $('#sizeConfiguratorModal form').on('submit', function (e) {
            e.preventDefault();

            const sizes = [];
            if ($('#sizeConfiguratorModal').data('combinations') == '') {
                $('#sizeConfiguratorModal select').each(function (i, el) {
                    $('[data-part=' + $(el).attr('name') + ']').text($(el).find('option:selected').text());
                    sizes.push($(el).val());
                });

                $('#sizeConfiguratorModal .calculated .result').text(sizes.join(" "));
            } else {
                const data = $('#sizeConfiguratorModal').data('combinations');

                const $select_x = $('#sizeConfiguratorModal select').eq(0);
                const $select_y = $('#sizeConfiguratorModal select').eq(1);

                const value_x = $select_x.find('option:selected').text();
                const value_y = $select_y.find('option:selected').text();

                $('[data-part=' + $select_x.attr('name') + ']').text(value_x);
                $('[data-part=' + $select_y.attr('name') + ']').text(value_y);

                $(data).each(function (i, el) {
                    if (el.option_x == value_x && el.option_y == value_y) {
                        sizes.push(el.value);
                        $('#sizeConfiguratorModal .calculated .result').text(el.value);
                        return false;
                    }
                });
            }

            $('#sizeConfiguratorModal #selection').fadeOut(function () {
                $('#sizeConfiguratorModal #overview').fadeIn();
                $('#sizeConfiguratorModal .modal-footer button').text('Bereken opnieuw');
            });

            const $variants = $(document).find('[data-variants]');
            const variants = JSON.parse($variants.text());
            const options = parseOptions(variants);

            const selected = getSelected();

            if (sizes.length > 0) {
                options.forEach(function (option, index) {
                    sizes.forEach(function (size) {
                        option.values.forEach(function (value) {
                            if (value.name === size) {
                                selected[index] = value.id;
                            }
                        });
                    });
                });
            }

            updateOptions(variants, selected);
        });
    }
}

function initProductTabs() {
    if ($('#tabsNav').length > 0) {
        $('#tabsNav li').on('click', function () {
            $('#tabsNav li').removeClass('active');
            $('#tabs .tab').removeClass('active');
            $(this).addClass('active');
            $('#tabs #' + $(this).data('tab')).addClass('active');
            $.fn.matchHeight._update()
        });
    }
}

function initProductTip() {
    if ($('[data-toggle=productTip]').length > 0) {
        $('[data-toggle=productTip]').off().on('click', function (e) {
            e.preventDefault();
            $(this).parent().find('.tip').toggleClass('show');
        });
    }
}